<template>
 <v-container
    id="plans"
    fluid
    tag="section"
    v-if="scopes.length"
  >
  <template v-if="!user">
    <div class="text-center">
      <v-img :src="logo" max-height="50px" contain class="mt-12"/>
      <h5>{{ $appName }}</h5>
      <h6 class="my-4">Plans</h6>
    </div>
  </template>
   <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th 
            v-for="(header, index) in headers" :key="`header-${index}`"
            :class="`text-${header.align}`"
            >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in scopes"
          :key="index"
        >
          <td 
            v-for="(header, i) in headers" 
            :key="`item-${i}`"
            :class="`text-${header.align}`"
            >
            <v-icon
              v-if="typeof item[header.value] === 'boolean'"
              :color="!item[header.value] ? 'red' : 'green'"
              >
              {{ !item[header.value] ? 'mdi-close-thick' : 'mdi-check-bold'}}
            </v-icon>
            <span v-else-if="item.key === 'monthly_price'">
              {{ item[header.value] | SubscriptionPriceFilter }}
            </span>
            <span v-else>
              {{ item[header.value] === Infinity ? 'Unlimited' : item[header.value] }}
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot v-if="user && get_plan">
        <tr>
          <td 
            v-for="(header, i) in headers" 
            :key="`item-${i}`"
            :class="`text-${header.align}`"
            >
            <v-btn
              v-if="i > 0 && header.id === get_plan.id"
              rounded
              color="success"
              outlined
              disabled>
              Current
            </v-btn>
            <v-btn
              v-else-if="i > 0 && header.id > get_plan.id"
              rounded
              color="success"
              @click="select_plan(header.id)"
              :loading="changing_plan === (header.id)"
              :disabled="changing_plan !== false"
              >
              {{ get_plan.id === 0 ? 'Start trial' : 'Upgrade' }}
            </v-btn>
            <v-btn
              v-else-if="i > 0"
              text
              small
              @click="select_plan(header.id)"
              :loading="changing_plan === (header.id)"
              :disabled="changing_plan !== false"
              >
              Choose
            </v-btn>
          </td>
        </tr>
        </tfoot>
    </template>
  </v-simple-table>

  <v-alert 
    v-for="conflict in conflicts" 
    :key="conflict"
    type="warning"
    >
  {{ clear_text[conflict] }}
  </v-alert>

</v-container>

</template>
<script>

import { mapGetters, mapState } from 'vuex'
import { SubscriptionPriceFilter } from '@/utils/filters'

export default {
  components: {

  },
  computed: {
    ...mapState({
      scopes: state => state.app.config.plan_limits,
      user: state => state.user.user,
      plans: state => state.app.config.plans
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      get_plan_conflicts: 'app/get_plan_conflicts',
    }),
    headers(){
      return ([{
            text: '',
            align: 'left',
            sortable: false,
            value: 'name',
          }]).concat(this.plans
          .filter(p => !p.hide)
          .map(p => ({
            text: p.name, 
            align: 'center',
            sortable: false, 
            value: p.title,
            id: p.id
          })))
    }
  },
  
  
  filters: {
    SubscriptionPriceFilter
  },
  data() {
    return {
      changing_plan: false,
      logo: require('@/assets/favicon-192.png'),
      clear_text: {
        amount_shelves: 'You have too many shelves in your warehouse. Delete shelves to be able to downgrade.',
        order_bucket_size: 'Your current settings allow more parcels per round than this plan. Change your setting before downgrading',
        amount_staff: 'You have more staff and/or invitations on your account than this plan allows. Remove staff accounts to be able to downgrade',
        store_transfer: 'Your store is currently transferred, which is not possible on this plan. Reclaim your store before changing plan.',
        connected_stores: 'You currently have more stores connected to your account than this plan allows. Remove stores before downgrading.',
        carrier_accounts: 'You currently have more carrier accounts connected than this plan allows. Remove carrier accounts before downgrading.',
        allow_product_scanning: 'You currently have barcode scanning enabled. Disable this feature to downgrade to this plan.',
        allow_shelf_scanning: 'You currently have shelf scanning enabled. Disable this feature to downgrade plan.',
        allow_auto_carrier: 'One or more of your stores use Carrier App Rates. Inactivate all these to downgrade plan.'
      },
      headersOLD: [
          {
            text: '',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Small Business',
            align: 'center',
            sortable: false,
            value: 'small',
            id: 2,
          },
          {
            text: 'Pro',
            align: 'center',
            sortable: false,
            value: 'pro',
            id: 3,
          },
          {
            text: 'Enterprise',
            align: 'center',
            sortable: false,
            value: 'enterprise',
            id: 4,
          },
      ],
      conflicts: []
    }
  },
  methods: {
    async select_plan(plan_id){
      console.log(plan_id)
      this.conflicts = []
      const plan_title = this.headers.find(i => i.id === plan_id).value
      const conflicts = this.get_plan_conflicts(plan_title)
      if(conflicts.length) return this.conflicts = conflicts
      this.changing_plan = plan_id
      const charge = await this.$store.dispatch('warehouse/change_company_plan', { plan_id })
      if(charge.confirmation_url ) window.open(charge.confirmation_url, '_top')
      else this.changing_plan = false
    }
  },
  mounted(){
    
  }
}
</script>
<style lang="scss" scoped>
    
</style>