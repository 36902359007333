import dayjs from 'dayjs'

export function SubscriptionPriceFilter(s){
  if(s === 0) return 'Free'
  else if (typeof s === 'string') return s
  return `$${s}/m`
}

export function OneIndexFilter(s){
  return parseInt(s) >= 0 ? `${parseInt(s) + 1}` : '--'
}

export function LetterFilter(s){
  return parseInt(s) >= 0 ? `${String.fromCharCode( 65 + parseInt(s))}` : '--'
}


export function CurrencyFilter(s) {
    if (s) {
      return `${(Number(s)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kr.`
    } else if (s instanceof Number) {
      return '0 kr';
    }
    return '---';
  }

  export function DateFilter(s) {
    return s ? dayjs(s).format('D MMM HH:mm') : '';
  }

  export function PointsFilter(s) {
    return s ? `${s} points` : '-- points'
  }